const socialMedia = [
  {
    name: "FlowKat",
    url: "https://flowkat.papercraft.dev/",
  },
  {
    name: "Github",
    url: "https://github.com/papercraft-projects/",
  },
  {
    name: "Homepage",
    url: "https://flowkat.yeonmu.dev",
  },
];

export default socialMedia;
